import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";

 const HotelDetail = () =>{
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Show 3 items per row by default
    slidesToScroll: 1, // Slide 1 item at a time
    arrows: true,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Slide every 3 seconds
    responsive: [
      {
        breakpoint: 768, // Tablet and mobile view
        settings: {
          slidesToShow: 1,  // Show 1 item per row on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
    return(
        <>
  
  <div id="utf_listing_gallery_part" className="utf_listing_section">
  <div className="utf_listing_slider utf_gallery_container margin-bottom-0">
    <a
      href="images/myimg/hotels/sandalsuits/lobby.jpg"
      data-background-image="images/myimg/hotels/sandalsuits/lobby.jpg"
      className="item utf_gallery"
    />
    <a
      href="images/myimg/hotels/sandalsuits/facade.jpg"
      data-background-image="images/myimg/hotels/sandalsuits/facade.jpg"
      className="item utf_gallery"
    />
    <a
      href="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
      data-background-image="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
      className="item utf_gallery"
    />
    <a
      href="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
      data-background-image="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
      className="item utf_gallery"
    />
  </div>
</div>

    
<div className="container">
  <div className="row utf_sticky_main_wrapper">
    <div className="col-lg-8 col-md-8">
      <div id="titlebar" className="utf_listing_titlebar">
        <div className="utf_listing_titlebar_title">
          <h2>
            Sandal Suites<span className="listing-tag">Hotel</span>
          </h2>
          <span>
            <a href="https://www.google.co.in/maps/place/Sandal+Suites+by+Lemon+Tree+Hotels/@28.49545,77.4026493,17z/data=!3m1!4b1!4m9!3m8!1s0x390ce85dbe3e05b3:0xbf8f1557f78b6f6e!5m2!4m1!1i2!8m2!3d28.49545!4d77.4026493!16s%2Fg%2F11g8yb87vq?entry=ttu">
              <i className="sl sl-icon-location" />
              Assotech Business Cresterra, 22, Sector-135 Noida{" "}
            </a>
          </span>
          <span className="call_now">
            <i className="sl sl-icon-phone" />
            0120 623 3333
          </span>
          <div className="utf_star_rating_section" data-rating="4.5">
            <div className="utf_counter_star_rating">(4.5) / (14 Reviews)</div>
          </div>
          <ul className="listing_item_social">
            <li>
              <a href="#">
                <i className="fa fa-bookmark" /> Bookmark
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-star" /> Add Review
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-flag" /> Featured
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-share" /> Share
              </a>
            </li>
            <li>
              <a href="#" className="now_open">
                Open Now
              </a>
            </li>
          </ul>
          <div className="utf_room_detail">
            <ul>
              <li>3 Rooms</li>
              <li>3 Bed Room</li>
              <li>4 Bed</li>
              <li>3 Bath Room</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="utf_listing_overview" className="utf_listing_section">
        <h3 className="utf_listing_headline_part margin-top-30 margin-bottom-30">
          About
        </h3>
        <p>
          Sandal Suites, operated by Lemon Tree Hotels, is situated just off the
          Noida-Greater Noida Expressway. These serviced apartments, best suited
          for extended stays, are located within Assotech Business Cresterra,
          the commercial complex in the IT hub of Sector 135, Noida. The
          property is in proximity to multiple residential as well as commercial
          complexes and multi-national corporations like GE, Genpact, Metlife,
          HCL, Accenture, KPMG, Samsung etc.
        </p>
        <div
          id="utf_listing_tags"
          className="utf_listing_section listing_tags_section margin-bottom-10 margin-top-0"
        >
          <a href="tel:+91 120 467 8888">
            <i className="sl sl-icon-phone" aria-hidden="true" />
            +91 120 467 8888
          </a>
          {/* <a href="mailto:reservations@rdnoida.com"><i class="fa fa-envelope-o" aria-hidden="true"></i>
          reservations@rdnoida.com</a> */}
          <a href="https://www.lemontreehotels.com/lemon-tree-hotel/noida/sandal-suites">
            <i className="sl sl-icon-globe" aria-hidden="true" />
            www.sdandal-suites.com
          </a>
        </div>
        {/* <div class="social-contact">
        <a href="#" class="facebook-link"><i class="fa fa-facebook"></i> Facebook</a>
        <a href="#" class="twitter-link"><i class="fa fa-twitter"></i> Twitter</a>
        <a href="#" class="instagram-link"><i class="fa fa-instagram"></i> Instagram</a>
        <a href="#" class="linkedin-link"><i class="fa fa-linkedin"></i> Linkedin</a>
        <a href="#" class="youtube-link"><i class="fa fa-youtube-play"></i> Youtube</a>
      </div> */}
      </div>
      <div
        id="utf_listing_tags"
        className="utf_listing_section listing_tags_section"
      >
        <h3 className="utf_listing_headline_part margin-top-30 margin-bottom-40">
          Popular Tags
        </h3>
        <a href="#">Luxury Hotels</a>
        <a href="#">Budget Hotels</a>
        <a href="#">Business Hotels</a>
        <a href="#">Airport Hotels</a>
        <a href="#">Resorts</a>
        <a href="#">Boutique Hotels</a>
        <a href="#">Eco-Friendly Hotels</a>
        <a href="#">Family Hotels</a>
        <a href="#">Beach Hotels</a>
        <a href="#">City Center Hotels</a>
        <a href="#">Conference Hotels</a>
        <a href="#">All-Inclusive Hotels</a>
      </div>
     
      <div id="utf_listing_amenities" className="utf_listing_section">
        <h3 className="utf_listing_headline_part margin-top-50 margin-bottom-40">
          Hotel Amenities
        </h3>
        <ul className="utf_listing_features checkboxes margin-top-0">
          <li>Free Wi-Fi</li>
          <li>Swimming Pool</li>
          <li>Fitness Center</li>
          <li>Restaurant</li>
          <li>Bar/Lounge</li>
          <li>Spa</li>
          <li>Room Service</li>
          <li>Parking</li>
          <li>Business Center</li>
          <li>Conference Facilities</li>
          <li>Pet-Friendly</li>
          <li>Airport Shuttle</li>
          <li>Non-Smoking Rooms</li>
          <li>Wheelchair Accessible</li>
          <li>Complimentary Breakfast</li>
          <li>Concierge Service</li>
          <li>Laundry Service</li>
          <li>24-Hour Front Desk</li>
          <li>Babysitting Services</li>
          <li>Private Beach</li>
        </ul>
      </div>
      <div id="utf_listing_reviews" className="utf_listing_section">
        <h3 className="utf_listing_headline_part margin-top-75 margin-bottom-20">
          Reviews <span>(08)</span>
        </h3>
        <div className="clearfix" />
        <div className="reviews-container">
          <div className="row">
            <div className="col-lg-3">
              <div id="review_summary">
                <strong>4.5</strong>
                <em>Superb Reviews</em>
                <small>Out of 15 Reviews</small>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-2 review_progres_title">
                  <small>
                    <strong>Quality</strong>
                  </small>
                </div>
                <div className="col-lg-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "95%" }}
                      aria-valuenow={95}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-1 review_progres_title">
                  <small>
                    <strong>77</strong>
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 review_progres_title">
                  <small>
                    <strong>Space</strong>
                  </small>
                </div>
                <div className="col-lg-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "90%" }}
                      aria-valuenow={90}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-1 review_progres_title">
                  <small>
                    <strong>15</strong>
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 review_progres_title">
                  <small>
                    <strong>Price</strong>
                  </small>
                </div>
                <div className="col-lg-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "70%" }}
                      aria-valuenow={70}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-1 review_progres_title">
                  <small>
                    <strong>18</strong>
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 review_progres_title">
                  <small>
                    <strong>Service</strong>
                  </small>
                </div>
                <div className="col-lg-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "40%" }}
                      aria-valuenow={40}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-1 review_progres_title">
                  <small>
                    <strong>10</strong>
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 review_progres_title">
                  <small>
                    <strong>Location</strong>
                  </small>
                </div>
                <div className="col-lg-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "20%" }}
                      aria-valuenow={20}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-1 review_progres_title">
                  <small>
                    <strong>05</strong>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="clearfix" />
      </div>
    </div>
    {/* Sidebar */}
    <div className="col-lg-4 col-md-4 margin-top-75 sidebar-search">
      <div
        className="verified-badge with-tip margin-bottom-30"
        data-tip-content="Listing has been verified and belongs business owner or manager."
      >
     
        <i className="sl sl-icon-check" /> Now Available
      </div>
      <div className="utf_box_widget margin-top-35">
        <h3>
          <i className="sl sl-icon-phone" /> Location &amp; Contact
        </h3>
        <img src="images/myimg/resturentmap.jpg" alt="" />
        <ul className="utf_listing_detail_sidebar">
          <li>
            <i className="sl sl-icon-map" />{" "}
            <a href="https://www.google.co.in/maps/place/Sandal+Suites+by+Lemon+Tree+Hotels/@28.4954547,77.4000744,17z/data=!3m1!4b1!4m9!3m8!1s0x390ce85dbe3e05b3:0xbf8f1557f78b6f6e!5m2!4m1!1i2!8m2!3d28.49545!4d77.4026493!16s%2Fg%2F11g8yb87vq?entry=ttu">
              Assotech Business Cresterra, 22, Sector-135 Noida Expressway,
              Noida 201301 India
            </a>
          </li>
          <li>
            <i className="sl sl-icon-phone" />
            0120 623 3333
          </li>
          {/* <li><i class="sl sl-icon-envelope-open"></i> <a
                href="mailto: reservations@rdnoida.com">reservations@rdnoida.com</a></li> */}
          <li>
            <i className="sl sl-icon-globe" />{" "}
            <a href="https://www.lemontreehotels.com/lemon-tree-hotel/noida/sandal-suites">
              www.sandal-suites.com
            </a>
          </li>
        </ul>
        <ul className="utf_social_icon rounded margin-top-10">
          <li>
            <a className="facebook" href="#">
              <i className="icon-facebook" />
            </a>
          </li>
          <li>
            <a className="twitter" href="#">
              <i className="icon-twitter" />
            </a>
          </li>
          <li>
            <a className="gplus" href="#">
              <i className="icon-gplus" />
            </a>
          </li>
          <li>
            <a className="linkedin" href="#">
              <i className="icon-linkedin" />
            </a>
          </li>
          <li>
            <a className="instagram" href="#">
              <i className="icon-instagram" />
            </a>
          </li>
        </ul>
      </div>
      <br/>
      <div className="utf_box_widget margin-bottom-35">
      <h3>list of Top Hotel In Noida</h3>
      <div className="row with-forms">
        <div className="col-md-12">
          <input type="text" placeholder="Enter Name" defaultValue="" />
        </div>
      </div>
      <div className="row with-forms">
        <div className="col-md-12">
          <input
            type="number"
            placeholder="Enter Mobile Number"
            defaultValue=""
          />
        </div>
      </div>
      <button className="button fullwidth_block margin-top-5">
        Send Enquiry
      </button>
    </div>
    
   
    
      <div className="utf_box_widget opening-hours margin-top-35">
        <h3>
          <i className="sl sl-icon-info" /> Google AdSense
        </h3>
        <span>
          <img src="images/google_adsense.jpg" alt="" />
        </span>
      </div>
    </div>
  </div>
</div>
<br/>
<section className="fullwidth_block padding-top-20 padding-bottom-50">
  <div className="container">
    <div className="row slick_carousel_slider">
      <div className="col-md-12">
        <h3 className="headline_part centered margin-bottom-25">
          View More Similar <span>Hotels</span>
        </h3>
        
        </div>
        <div className="row">
          <div className="col-md-12">
          <Slider {...settings} className="simple_slick_carousel_block1 utf_dots_nav">
      {[1, 2, 3, 4].map((item, index) => (
        <div className="utf_carousel_item" key={index}>
          <Link to="#" className="utf_listing_item-container compact">
            <div className="utf_listing_item">
              <img src="images/utf_listing_item-02.jpg" alt="" />
              <span className="tag">
                <i className="im im-icon-Electric-Guitar" /> Events
              </span>
              <div className="utf_listing_item_content">
                <h3>The Lounge &amp; Bar</h3>
                <span>
                  <i className="fa fa-map-marker" /> Noida sector 2
                </span>
                <span>
                  <i className="fa fa-phone" /> 1234567890
                </span>
              </div>
            </div>
            <div className="utf_star_rating_section" data-rating="4.5">
              <div className="utf_counter_star_rating">(4.5)</div>
              <span className="utf_view_count">
                <i className="fa fa-eye" /> 822+
              </span>
              <span className="like-icon" />
            </div>
          </Link>
        </div>
      ))}
    </Slider>
          </div>
        </div>
      </div>
    </div>
  </section>
        </>
    )
 }


 export default HotelDetail;