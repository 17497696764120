import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';

const Addlisting = () => {
  
    const [logo, setLogo] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
  
    const onDropLogo = (acceptedFiles) => {
      setLogo(URL.createObjectURL(acceptedFiles[0]));
    };
  
    const onDropCover = (acceptedFiles) => {
      setCoverImage(URL.createObjectURL(acceptedFiles[0]));
    };
  
    const onDropGallery = (acceptedFiles) => {
      setGalleryImages((prevImages) => [
        ...prevImages,
        ...acceptedFiles.map(file => URL.createObjectURL(file))
      ]);
    };
  
    const removeImage = (index) => {
      setGalleryImages(galleryImages.filter((_, i) => i !== index));
    };
  
  return (
  <>
  <div id="titlebar" className="gradient margin-bottom-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h2>Add Listing</h2>
        <nav id="breadcrumbs">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>Add Listing</li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<section
  className="fullwidth_block padding-bottom-70"
  data-background-color="#f9f9f9"
>
  <div className="container">
    <div className="row">
    <div className="col-md-12 text-center margin-top-75">
          <h3 className="headline_part centered  mb-0">
            
          Promote Your Business in  <span>Noida</span>
          </h3>
          <p>Join the growing community of businesses in Noida by adding your business to our directory. Reach a wider audience and boost your visibility in the local market</p>
        </div>
    </div>
    <div className="row">
  <div className="col-lg-12">
    <div id="utf_add_listing_part">
      <div className="add_utf_listing_section">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-user" /> Have an Account?
          </h3>
        </div>
        <div className="row with-forms">
          <div className="col-md-12">
            <div className="form-group lis-relative">
              Sign in If you don’t have an account you can create one below by
              entering your email address/username. Your account details will be
              confirmed via email.
              <a
                href="#"
                className="login_form"
              >
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-tag" /> Categories &amp; Tags
          </h3>
        </div>
        <div className="row with-forms">
          <div className="col-md-6">
            <h5>Listing Title</h5>
            <input
              type="text"
              className="search-field"
              name="listing_title"
              id="listing_title"
              placeholder="Listing Title"
              defaultValue=""
            />
          </div>
          <div className="col-md-6">
            <h5>Keywords</h5>
            <input
              type="text"
              name="keywords"
              id="keywords"
              placeholder="Keywords..."
              defaultValue=""
            />
          </div>
        </div>
        <div className="row with-forms">
          <div className="col-md-6">
            <h5>Category</h5>
            <div className="intro-search-field utf-chosen-cat-single">
              <select
                className="selectpicker default"
                data-selected-text-format="count"
                data-size={7}
                title="Select Category"
              >
                <option>Eat &amp; Drink</option>
                <option>Shops</option>
                <option>Hotels</option>
                <option>Restaurants</option>
                <option>Fitness</option>
                <option>Events</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <h5>Tags(optional)</h5>
            <div className="intro-search-field utf-chosen-cat-single">
              <select
                className="selectpicker default"
                data-selected-text-format="count"
                data-size={7}
                title="Select Tags"
              >
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
                <option>Four</option>
                <option>Five</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row with-forms">
          <div className="col-md-12">
            <h5>Listing Tags</h5>
            <input
              type="text"
              className="search-field"
              name="listing_title"
              id="listing_title"
              placeholder="Listing Tags"
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-map" /> Location
          </h3>
        </div>
        <div className="utf_submit_section">
          <div className="row with-forms">
            <div className="col-md-6">
              <h5>City</h5>
              <div className="intro-search-field utf-chosen-cat-single">
                <select
                  className=" default"
                  data-selected-text-format="count"
                  data-size={7}
                  title="Select City"
                >
                  <option>New York</option>
                  <option>Argentina</option>
                  <option>Chicago</option>
                  <option>Azerbaijan</option>
                  <option>Indonesia</option>
                  <option>India</option>
                  <option>Thailand</option>
                  <option>Gambia</option>
                  <option>Spain</option>
                  <option>Iraq</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <h5>Address</h5>
              <input
                type="text"
                className="input-text"
                name="address"
                id="address"
                placeholder="Address"
                defaultValue=""
              />
            </div>
      
          </div>
        </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-picture" /> Images
          </h3>
        </div>
        <div className="row with-forms">
      {/* Logo Section */}
      <div className="utf_submit_section col-md-4">
        <h4>Logo</h4>
        <div>
          
          <Dropzone onDrop={onDropLogo} />
          {logo && <img src={logo} alt="Logo" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />}
        </div>
      </div>

      {/* Cover Image Section */}
      <div className="utf_submit_section col-md-4">
        <h4>Cover Image</h4>
        <div>
        
          <Dropzone onDrop={onDropCover}
         
          />
          {coverImage && <img src={coverImage} alt="Cover" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />}
        </div>
      </div>

      {/* Gallery Images Section */}
      <div className="utf_submit_section col-md-4">
        <h4>Gallery Images</h4>
        <div>
       
          <Dropzone onDrop={onDropGallery} multiple />
          <div className="gallery">
            {galleryImages.map((image, index) => (
              <div key={index} className="gallery-item">
                <img src={image} alt={`Gallery ${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                <button onClick={() => removeImage(index)}><i class="fa fa-close"></i></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-list" /> Name &amp; Description
          </h3>
        </div>
        <div className="row with-forms">
          <div className="col-md-6">
            <h5>Name</h5>
            <input type="text" placeholder="Name" />
          </div>
          <div className="col-md-6">
            <h5>Email</h5>
            <input type="text" placeholder="Email" />
          </div>
          <div className="col-md-6">
            <h5>Title</h5>
            <input type="text" placeholder="Title" />
          </div>
          <div className="col-md-6">
            <h5>Tagline</h5>
            <input type="text" placeholder="Tagline" />
          </div>
          <div className="col-md-12">
            <h5>Description</h5>
            <textarea
              name="summary"
              cols={40}
              rows={3}
              id="description"
              placeholder="Description..."
              spellCheck="true"
              defaultValue={""}
            />
          </div>
        </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-home" /> Amenities
          </h3>
        </div>
        <div className="checkboxes in-row amenities_checkbox">
          <ul>
            <li>
              <input id="check-a" type="checkbox" name="check" />
              <label htmlFor="check-a">Car Parking</label>
            </li>
            <li>
              <input id="check-b" type="checkbox" name="check" />
              <label htmlFor="check-b">Takes Reservations</label>
            </li>
            <li>
              <input id="check-c" type="checkbox" name="check" />
              <label htmlFor="check-c">Street Parking</label>
            </li>
            <li>
              <input id="check-d" type="checkbox" name="check" />
              <label htmlFor="check-d">Elevator in Building</label>
            </li>
            <li>
              <input id="check-e" type="checkbox" name="check" />
              <label htmlFor="check-e">Outdoor Seating</label>
            </li>
            <li>
              <input id="check-f" type="checkbox" name="check" />
              <label htmlFor="check-f">Friendly Workspace</label>
            </li>
            <li>
              <input id="check-g" type="checkbox" name="check" />
              <label htmlFor="check-g">Wireless Internet</label>
            </li>
            <li>
              <input id="check-h" type="checkbox" name="check" />
              <label htmlFor="check-h">Good for Kids</label>
            </li>
            <li>
              <input id="check-i" type="checkbox" name="check" />
              <label htmlFor="check-i">Events</label>
            </li>
            <li>
              <input id="check-j" type="checkbox" name="check" />
              <label htmlFor="check-j">Smoking Allowed</label>
            </li>
            <li>
              <input id="check-k" type="checkbox" name="check" />
              <label htmlFor="check-k">Wheelchair Accessible</label>
            </li>
            <li>
              <input id="check-l" type="checkbox" name="check" />
              <label htmlFor="check-l">Accepted Bank Cards</label>
            </li>
          </ul>
        </div>
      </div>
  
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-tag" /> Add Pricing
          </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="utf_pricing_list_section">
              <tbody className="ui-sortable">
                <tr className="pricing-list-item pattern ui-sortable-handle">
                  <td>
                    <div className="fm-move">
                      <i className="sl sl-icon-cursor-move" />
                    </div>
                    <div className="fm-input pricing-name">
                      <input type="text" placeholder="Title" />
                    </div>
                    <div className="fm-input pricing-ingredients">
                      <input type="text" placeholder="Description" />
                    </div>
                    <div className="fm-input pricing-price">
                      <i className="data-unit">$</i>
                      <input type="text" placeholder="Price" data-unit="$" />
                    </div>
                    <div className="fm-close">
                      <a className="delete" href="#">
                        <i className="fa fa-remove" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <a href="#" className="button add-pricing-list-item">
              Add Item
            </a>
            <a href="#" className="button add-pricing-submenu">
              Add Category
            </a>
          </div>
        </div>
      </div>
      <div className="add_utf_listing_section margin-top-45">
        <div className="utf_add_listing_part_headline_part">
          <h3>
            <i className="sl sl-icon-docs" /> Your Listing Feature
          </h3>
        </div>
        <div className="checkboxes in-row amenities_checkbox">
          <ul>
            <li>
              <input id="check-a1" type="checkbox" name="check" />
              <label htmlFor="check-a1">Accepts Credit Cards</label>
            </li>
            <li>
              <input id="check-b1" type="checkbox" name="check" />
              <label htmlFor="check-b1">Smoking Allowed</label>
            </li>
            <li>
              <input id="check-c1" type="checkbox" name="check" />
              <label htmlFor="check-c1">Bike Parking</label>
            </li>
            <li>
              <input id="check-d1" type="checkbox" name="check" />
              <label htmlFor="check-d1">Hostels</label>
            </li>
            <li>
              <input id="check-e1" type="checkbox" name="check" />
              <label htmlFor="check-e1">Wheelchair Accessible</label>
            </li>
            <li>
              <input id="check-f1" type="checkbox" name="check" />
              <label htmlFor="check-f1">Wheelchair Internet</label>
            </li>
            <li>
              <input id="check-g1" type="checkbox" name="check" />
              <label htmlFor="check-g1">Wheelchair Accessible</label>
            </li>
            <li>
              <input id="check-h1" type="checkbox" name="check" />
              <label htmlFor="check-h1">Parking Street</label>
            </li>
            <li>
              <input id="check-i1" type="checkbox" name="check" />
              <label htmlFor="check-i1">Wireless Internet</label>
            </li>
          </ul>
        </div>
      </div>
      <a href="#" className="button preview">
        Save &amp; Preview
      </a>
    </div>
  </div>

</div>


  </div>
</section>
  </>
  )
}
const Dropzone = ({ onDrop, multiple }) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });
  
    return (
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop files here, or click to select files</p>
      </div>
    );
  };
export default Addlisting
