import React from 'react'
import { Link } from 'react-router-dom'

const Category = () => {
  return (
    <>
    <div id="titlebar" className="gradient margin-bottom-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Our Popular Categories</h2>
            <nav id="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Categories</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 text-left">
          <h3 className="headline_part margin-top-75 mb-0">
          
            Browse Our All <span>Categories</span>
          </h3>
          <p>
        
            Explore a wide range of categories and find everything you need in
            Noida
          </p>
        </div>
        <div className="col-md-4 col-sm-5">
          <div className="contact-form-action margin-top-75 mb-0">
            <form method="" >
              <span className="fa  fa-search" />
              <input
                className="form-control"
                type="text"
                placeholder="Search Category"
                required=""
              />
              <button className="utf_theme_btn" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="row margin-top-30">
        <div className="col-md-12">
          <div className="container_categories_box margin-top-5 margin-bottom-30">
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/acrepair.jfif" alt="" />
              <h4>Ac Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Astrologers.avif" alt="" />
              <h4>Astrologers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/bodymasage.jpg" alt="" />
              <h4>Body Massage Centers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/beauty.png" alt="" />
              <h4>Beauty Spa</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/cab_3660533.png" alt="" />
              <h4>Car Hire</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/catering_1940981.png" alt="" />
              <h4>Caterers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/ca.png" alt="" />
              <h4>Chartered Accountant</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/online-learning_2972272.png"
                alt=""
              />
              <h4>Computer Training</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Courierservises.avif" alt="" />
              <h4>Courier</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/computer_8943445.png" alt="" />
              <h4>Computer Repair</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/car-repair_7982781.png" alt="" />
              <h4>Car Repair</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/checkup_8422113.png" alt="" />
              <h4>Dermatologists</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/dentist_2317929.png" alt="" />
              <h4>Dentists</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/electrician_6009312.png" alt="" />
              <h4>Electricians</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/events_5837046.png" alt="" />
              <h4>Event Organizer</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/estateagent.png" alt="" />
              <h4>Real Estate</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/production-line_13505597.png"
                alt=""
              />
              <h4>Fabricators</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/sofa_14432506.png" alt="" />
              <h4>Furniture Repair Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/hospital.avif" alt="" />
              <h4>Hospitals</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/homecare_2621961.png" alt="" />
              <h4>House Keeping Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/lifestyle_6193497.png" alt="" />
              <h4>Hobbies</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/drawing_14934377.png" alt="" />
              <h4>Interior Designers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/blogger_15560274.png" alt="" />
              <h4>Internet Website Designers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/jewellery_10347691.png" alt="" />
              <h4>Jewellery Showrooms</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/judge_15673332.png" alt="" />
              <h4>Lawyers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/delivery-truck_4735926.png"
                alt=""
              />
              <h4>Transporters</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/photographer.png" alt="" />
              <h4>Photographers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/nursing.jpg" alt="" />
              <h4>Nursing Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Printing Services.jpg" alt="" />
              <h4>Printing Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/placement.png" alt="" />
              <h4>Placement Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/Pest Control Services.jpg"
                alt=""
              />
              <h4>Pest Control Services</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/Painting Contractors.png"
                alt=""
              />
              <h4>Painting Contractors</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Packers & Movers.png" alt="" />
              <h4>Packers &amp; Movers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Scrap Dealers.png" alt="" />
              <h4>Scrap Dealers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/buyer.png" alt="" />
              <h4>Scrap Buyers</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/Registration Contractors.jpg"
                alt=""
              />
              <h4>Registration Contractors</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Secrity System.png" alt="" />
              <h4>Secrity System</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img
                src="images/myimg/categories/operations-training-icon-8.png"
                alt=""
              />
              <h4>Coaching</h4>
            </Link>
            <Link to="/" className="utf_category_small_box_part">
              <img src="images/myimg/categories/Vocational Training.jpg" alt="" />
              <h4>Vocational Training</h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default Category
