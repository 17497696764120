import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    <>
    <br/><br/>
     <section className="utf_cta_area_item utf_cta_area2_block">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="utf_subscribe_block clearfix">
            <div className="col-md-8 col-sm-7">
              <div className="section-heading">
                <h2 className="utf_sec_title_item utf_sec_title_item2">
                  Subscribe to Newsletter!
                </h2>
                <p className="utf_sec_meta">
                  Get the latest updates, special offers, and exciting news from
                  Just Noida directly to your inbox. Don’t miss out!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-5">
              <div className="contact-form-action">
                <form method="post">
                  <span className="la la-envelope-o" />
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Enter your email"
                    required=""
                  />
                  <button className="utf_theme_btn" type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    <footer id="footer" className="footer_sticky_part">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div id="logo">
              <Link to="/">
                <img src="images/logo.png" alt="Just Noida Logo" />
              </Link>
           
            </div>
            <br/><br/>
              <p>
              At Just Noida, we are dedicated to bringing the best of Noida to you. 
              Whether you’re searching for a new restaurant, a reliable fitness center, 
              or an event to attend, we have it all in one place.
            </p>
            <h4>Follow Us</h4>
            <div className="icons">
              <a href="#">
                <i className="fa fa-facebook" />
              </a>
              <a href="#">
                <i className="fa fa-instagram" />
              </a>
              <a href="#">
                <i className="fa fa-linkedin" />
              </a>
              <a href="#">
                <i className="fa fa-twitter" />
              </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-3 col-xs-6">
            <h4>Useful Links</h4>
            <ul className="social_footer_link">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/listing">Listing</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>

          <div className="col-md-2 col-sm-3 col-xs-6">
            <h4>My Account</h4>
            <ul className="social_footer_link">
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/profile">Profile</Link></li>
              <li><Link to="/my-listing">My Listing</Link></li>
              <li><Link to="/favorites">Favorites</Link></li>
            </ul>
          </div>

          <div className="col-md-2 col-sm-3 col-xs-6">
            <h4>More Links</h4>
            <ul className="social_footer_link">
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/partners">Our Partners</Link></li>
              <li><Link to="/how-it-works">How It Works</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>

          <div className="col-md-2 col-sm-3 col-xs-6">
            <h4>Help</h4>
            <ul className="social_footer_link">
              <li><Link to="/sign-in">Sign In</Link></li>
              <li><Link to="/register">Register</Link></li>
              <li><Link to="/add-listing">Add Listing</Link></li>
              <li><Link to="/pricing">Pricing</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="footer_copyright_part">
              Copyright © 2024 
              <a href="https://justnoida.com/" target="_blank" rel="noopener noreferrer"> Just Noida</a>. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
      
      <div id="bottom_backto_top">
        <a href="#">
          <i className="fa fa-angle-up" />
        </a>
      </div>
    </footer>
    </>
  
  );
};

export default Footer;
