import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import Typed from 'typed.js';
const Home = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Show 3 items per row by default
    slidesToScroll: 1, // Slide 1 item at a time
    arrows: true,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Slide every 3 seconds
    responsive: [
      {
        breakpoint: 768, // Tablet and mobile view
        settings: {
          slidesToShow: 1,  // Show 1 item per row on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  const options = [
    { value: 'ac-service', label: 'AC Service' },
    { value: 'astrologers', label: 'Astrologers' },
    { value: 'body-massage-centers', label: 'Body Massage Centers' },
    { value: 'beauty-spa', label: 'Beauty Spa' },
    { value: 'car-hire', label: 'Car Hire' },
    { value: 'caterers', label: 'Caterers' },
    { value: 'chartered-accountant', label: 'Chartered Accountant' },
    { value: 'computer-training-institutes', label: 'Computer Training Institutes' },
    { value: 'courier-services', label: 'Courier Services' },
    { value: 'computer-laptop-repair-services', label: 'Computer & Laptop Repair & Services' },
    { value: 'car-repair-services', label: 'Car Repair & Services' },
    { value: 'dermatologists', label: 'Dermatologists' },
    { value: 'dentists', label: 'Dentists' },
    { value: 'electricians', label: 'Electricians' },
    { value: 'event-organizer', label: 'Event Organizer' },
    { value: 'real-estate', label: 'Real Estate' },
    { value: 'fabricators', label: 'Fabricators' },
    { value: 'furniture-repair-services', label: 'Furniture Repair Services' },
    { value: 'hospitals', label: 'Hospitals' },
    { value: 'housekeeping-services', label: 'Housekeeping Services' },
    { value: 'hobbies', label: 'Hobbies' },
    { value: 'interior-designers', label: 'Interior Designers' },
    { value: 'internet-website-designers', label: 'Internet Website Designers' },
    { value: 'jewellery-showrooms', label: 'Jewellery Showrooms' },
    { value: 'lawyers', label: 'Lawyers' },
    { value: 'transporters', label: 'Transporters' },
    { value: 'photographers', label: 'Photographers' },
    { value: 'nursing-services', label: 'Nursing Services' },
    { value: 'printing-publishing-services', label: 'Printing & Publishing Services' },
    { value: 'placement-services', label: 'Placement Services' },
    { value: 'pest-control-services', label: 'Pest Control Services' },
    { value: 'painting-contractors', label: 'Painting Contractors' },
    { value: 'packers-movers', label: 'Packers & Movers' },
    { value: 'scrap-dealers', label: 'Scrap Dealers' },
    { value: 'scrap-buyers', label: 'Scrap Buyers' },
    { value: 'registration-consultants', label: 'Registration Consultants' },
    { value: 'security-system', label: 'Security System' },
    { value: 'coaching', label: 'Coaching' },
    { value: 'vocational-training', label: 'Vocational training' },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      marginTop: '-6px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
  };
  //   typed word function

  const typedRef = useRef(null);

  useEffect(() => {
    // Ensure the element exists before initializing Typed.js
    const initializeTyped = () => {
      const typedElement = document.querySelector('.typed-words');
      if (typedElement) {
        typedRef.current = new Typed(typedElement, {
          strings: [
            "Everything",
            "Realestate",
            "Restaurants",
            "Hotels",
            "Education",
            "Hospital",
            "Parking",
            "Gym",
            "Consultants",
            "Courier Services",
            "PG/Hostels"
          ],
          typeSpeed: 80,
          backSpeed: 80,
          backDelay: 4000,
          startDelay: 1000,
          loop: true,
          showCursor: true
        });
      } else {
        console.error('Typed.js target element not found');
      }
    };

    // Initialize Typed.js after a short delay to ensure DOM is ready
    const timer = setTimeout(initializeTyped, 100);

    // Clean up on unmount
    return () => {
      clearTimeout(timer);
      if (typedRef.current) {
        typedRef.current.destroy();
      }
    };
  }, []);

  //   marques

  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeItems = marqueeRef.current.querySelectorAll('li');

    marqueeItems.forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      marqueeItems.forEach(item => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  const handleMouseEnter = () => {
    marqueeRef.current.classList.add('paused');
  };

  const handleMouseLeave = () => {
    marqueeRef.current.classList.remove('paused');
  };


  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter1 = () => {
    setIsPaused(true);
  };

  const handleMouseLeave1 = () => {
    setIsPaused(false);
  };


  return (
    <>
      <div className="clearfix"/>
      <div className="search_container_block overlay_dark_part">
        <div className="main_inner_search_block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 style={{ fontWeight: 600, color: "#fff" }}>
                  Welcome to Noida!
                </h4>
                <h2>
                  Explore <span className="typed-words" /> In Noida
                </h2>
                <h4>Find trusted local businesses and services in one place.</h4>
                <div className="main_input_search_part">
                  <div className="main_input_search_part_item">
                    <input
                      type="text"
                      placeholder="What are you looking for?"
                      defaultValue=""
                    />
                  </div>
                  <div className="main_input_search_part_item location">
                    <input
                      type="text"
                      placeholder="Search Location..."
                      defaultValue=""
                    />
                    <Link to="#">
                      <i className="sl sl-icon-location" />
                    </Link>
                  </div>
                  <div className="main_input_search_part_item intro-search-field">
                    <Select
                      options={options}
                      styles={customStyles}
                      placeholder="All Categories"
                      isSearchable
                      isClearable
                    />
                  </div>
                  <button className="button" onClick="window.location">
                    Search
                  </button>
                </div>
                <div className="main_popular_categories">
                  <h3>Or Browse Popular Categories</h3>
                  <div className="marquee-container">
                    <div className="marquee-content">
                      <ul className="marquee main_popular_categories_list" ref={marqueeRef}>
                        <li>
                          <Link to="resturent">
                            <div className="utf_box">
                              <i className="im im-icon-Chef-Hat" aria-hidden="true" />
                              <p>Restaurant</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="gym">
                            <div className="utf_box">
                              <i className="im im-icon-Dumbbell" aria-hidden="true" />
                              <p>Fitness</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Electric-Guitar" aria-hidden="true" />
                              <p>Events</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hotel">
                            <div className="utf_box">
                              <i className="im im-icon-Hotel" aria-hidden="true" />
                              <p>Hotels</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Home-2" aria-hidden="true" />
                              <p>Real Estate</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Business-Man" aria-hidden="true" />
                              <p>Business</p>
                            </div>
                          </Link>
                        </li>
                        {/* Duplicate the list items for continuous scroll */}
                        <li>
                          <Link to="resturent">
                            <div className="utf_box">
                              <i className="im im-icon-Chef-Hat" aria-hidden="true" />
                              <p>Restaurant</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="gym">
                            <div className="utf_box">
                              <i className="im im-icon-Dumbbell" aria-hidden="true" />
                              <p>Fitness</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Electric-Guitar" aria-hidden="true" />
                              <p>Events</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Hotel" aria-hidden="true" />
                              <p>Hotels</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Home-2" aria-hidden="true" />
                              <p>Real Estate</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="utf_box">
                              <i className="im im-icon-Business-Man" aria-hidden="true" />
                              <p>Business</p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="utf_video_container">
          <video poster="images/search_video_bg.jpg" loop autoPlay muted>
            <source src="images/demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="headline_part centered margin-top-75 mb-0">

              Most Popular <span>Categories</span>
            </h3>
            <p>Browse the most desirable categories</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="container_categories_box margin-top-5 margin-bottom-30">
              <Link to="/hotel" className="utf_category_small_box_part">
                <img src="images/myimg/categories/hotel.avif" alt="" />
                <h4>Hotels</h4>
              </Link>
              <Link to="resturent" className="utf_category_small_box_part">
                <img src="images/myimg/categories/resturant.avif" alt="" />
                <h4>Restaurant</h4>
              </Link>
              <Link to="#" className="utf_category_small_box_part">
                <img src="images/myimg/categories/education.avif" alt="" />
                <h4>Education</h4>
              </Link>
              <Link to="pg" className="utf_category_small_box_part">
                <img src="images/myimg/categories/pghostel.avif" alt="" />
                <h4>PG/Hostels</h4>
              </Link>
              <Link to="hospital" className="utf_category_small_box_part">
                <img src="images/myimg/categories/hospital.avif" alt="" />
                <h4>Hospital</h4>
              </Link>
              <Link to="courier" className="utf_category_small_box_part">
                <img src="images/myimg/categories/Courierservises.avif" alt="" />
                <h4>Courier Servises</h4>
              </Link>
              <Link to="chemist" className="utf_category_small_box_part">
                <img src="images/myimg/categories/chemist.jpg" alt="" />
                <h4>Chemist</h4>
              </Link>
              <Link to="estateagent" className="utf_category_small_box_part">
                <img src="images/myimg/categories/estateagent.png" alt="" />
                <h4>Estate Agent</h4>
              </Link>
              <Link to="beauty" className="utf_category_small_box_part">
                <img src="images/myimg/categories/beauty.png" alt="" />
                <h4>Beauty Spa</h4>
              </Link>
              <Link
                to="weddingplanning"
                className="utf_category_small_box_part"
              >
                <img
                  src="images/myimg/categories/wedding-party-abstract-illustration.png"
                  alt=""
                />
                <h4>Wedding Planning</h4>
              </Link>
              <Link to="gym" className="utf_category_small_box_part">
                <img src="images/myimg/categories/gym.png" alt="" />
                <h4>Gym</h4>
              </Link>
              <Link to="parking" className="utf_category_small_box_part">
                <img src="images/myimg/category/parking2.png" alt="" />
                <h4>Parking</h4>
              </Link>
              <Link
                to="computertraning"
                className="utf_category_small_box_part"
              >
                <img
                  src="images/myimg/categories/online-learning_2972272.png"
                  alt=""
                />
                <h4>Computer Training</h4>
              </Link>
              <Link to="eventorganizer" className="utf_category_small_box_part">
                <img src="images/myimg/categories/events_5837046.png" alt="" />
                <h4>Event Organizer</h4>
              </Link>
              <Link to="interior" className="utf_category_small_box_part">
                <img src="images/myimg/categories/drawing_14934377.png" alt="" />
                <h4>Interior Designers</h4>
              </Link>
              <Link to="transporters" className="utf_category_small_box_part">
                <img
                  src="images/myimg/categories/delivery-truck_4735926.png"
                  alt=""
                />
                <h4>Transporters</h4>
              </Link>
            </div>
            <div className="col-md-12 centered_content">

              <Link to="category" className="button border margin-top-20">
                View all
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section
        className="fullwidth_block margin-top-65 padding-top-75 padding-bottom-70"
        data-background-color="#f9f9f9"
      >
        <div className="container">
          <div className="row slick_carousel_slider">
            <div className="col-md-12 text-center">
              <h3 className="headline_part centered  mb-0">

                Most Visited <span>Places</span>
              </h3>
              <p>Explore the greates places in the city</p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider {...settings} className="simple_slick_carousel_block1 utf_dots_nav">
                  {[1, 2, 3, 4].map((item, index) => (
                    <div className="utf_carousel_item" key={index}>
                      <Link to="#" className="utf_listing_item-container compact">
                        <div className="utf_listing_item">
                          <img src="images/utf_listing_item-02.jpg" alt="utf_listing_item" />
                          <span className="tag">
                            <i className="im im-icon-Electric-Guitar" /> Events
                          </span>
                          <div className="utf_listing_item_content">
                            <h3>The Lounge &amp; Bar</h3>
                            <span>
                              <i className="fa fa-map-marker" /> Noida sector 2
                            </span>
                            <span>
                              <i className="fa fa-phone" /> 9999999999
                            </span>
                          </div>
                        </div>
                        <div className="utf_star_rating_section" data-rating="4.5">
                          <div className="utf_counter_star_rating">(4.5)</div>
                          <span className="utf_view_count">
                            <i className="fa fa-eye" /> 822+
                          </span>
                          <span className="like-icon" />
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Link
        to="#listings_grid_full_width"
        className="flip-banner parallax"
        data-background="images/slider-bg-02.jpg"
        data-color="rgb(30 33 34)"
        data-color-opacity="0.85"
        data-img-width={2500}
        data-img-height={1666}
      >
        <div className="flip-banner-content">
          <h2 className="flip-visible">Browse Listings Attractions List</h2>
        </div>
      </Link>
      <section
        className="fullwidth_block margin-top-65 padding-top-75 padding-bottom-70"
        data-background-color="#fff"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center padding-bottom-50">
              <h3 className="headline_part centered ">
                New on the
                <span>Blog</span>
              </h3>
              <p>Read our recent blogs</p>
            </div>
            <div className="marquee-container">
              <div className="marquee-content">
                <div className={`marquee ${isPaused ? 'paused' : ''}`}>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img src="images/myimg/ban2.jpg" className="card-img-top" alt="Card image" />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">What's New In Noida</h5>
                      <Link to="#" className="btn enquiry">Explore Now</Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img src="images/category-box-01.jpg" className="card-img-top" alt="Card image" />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Shopping Destinations in Noida</h5>
                      <Link to="#" className="btn enquiry">Explore Now</Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/category-box-03.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Growing Industries in Noida</h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/category-box-04.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Successful Businesses in Noida</h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/category-box-06.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Noida's Top Business Parks</h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/category-box-07.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        Tech Startups Thriving in Noida
                      </h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/myimg/ban2.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">What's New In Noida</h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  <div className="searchcard card" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                    <div className="searchimg">
                      <img
                        src="images/category-box-01.jpg"
                        className="card-img-top"
                        alt="Card image"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Shopping Destinations in Noida</h5>
                      <Link to="#" className="btn enquiry">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                  {/* Repeat for other cards */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
