import React from 'react'

const Contact = () => {
  return (
<>
<div id="titlebar" className="gradient margin-bottom-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h2>Contact Us</h2>
        <nav id="breadcrumbs">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>Contact Us</li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
<section
  className="fullwidth_block padding-bottom-70"
  data-background-color="#f9f9f9"
>
  <div className="container">
    <div className="row">
    <div className="col-md-12 text-center margin-top-75">
          <h3 className="headline_part centered  mb-0">
            
            Get In <span>Touch</span>
          </h3>
          <p>If you have any questions, feedback, or need assistance with navigating our platform, we're here to assist you. Use the form below to get in touch, and we'll respond promptly to help you with your needs</p>
        </div>
    </div>
    <div className="row container_icon">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="box_icon_two box_icon_with_line">
        
          <i className="im im-icon-Map-Marker2" />
          <h3>Office Address</h3>
          <p>
            Noida uttar Pradesh
          </p>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="box_icon_two box_icon_with_line">
          
          <i className="im im-icon-Phone" />
          <h3>Give Us a Call </h3>
          <p>
          24/7 Line +91 9990799940
          </p>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="box_icon_two">
         
          <i className="im im-icon-Mail-Add" />
          <h3>Email Us</h3>
          <p>
          justnoidainfo@gmail.com

          </p>
        </div>
      </div>
    </div>
  </div>
</section>


{/* <div className="parallax" data-background="images/slider-bg-02.jpg">
  <div className="utf_text_content">
  <div className="container margin-bottom-75">
  <div className="row">
    <div className="col-lg-8 col-md-8 utf_listing_payment_section">
      <div className="utf_booking_listing_section_form margin-bottom-40">
        <h3>
          <i className="sl sl-icon-paper-plane" /> Billing Information
        </h3>
        <div className="row">
          <div className="col-md-6">
            <label>First Name</label>
            <input type="text" defaultValue="" placeholder="First Name" />
          </div>
          <div className="col-md-6">
            <label>Last Name</label>
            <input type="text" defaultValue="" placeholder="Last Name" />
          </div>
          <div className="col-md-6">
            <div className="medium-icons">
              <label>E-Mail</label>
              <input type="text" defaultValue="" placeholder="Email" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="medium-icons">
              <label>Phone</label>
              <input type="text" defaultValue="" placeholder="Phone" />
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div className="col-lg-4 col-md-4 utf_listing_payment_section">
    <img src='images/myimg/contact.webp' alt='contact'></img>
    
    </div>
  </div>
</div>
  </div>
</div> */}


<section
  className="fullwidth_block padding-bottom-70"
  data-background-color="#fff"
>

<div className="container margin-top-75">
  <div className="row">
    <div className="col-lg-8 col-md-8 utf_listing_payment_section">
      <div className="utf_booking_listing_section_form margin-bottom-40">
        <h3>
          <i className="sl sl-icon-paper-plane" /> Drop us a message
        </h3>
        <div className="row">
          <div className="col-md-6">
            <label>First Name</label>
            <input type="text" defaultValue="" placeholder="First Name" />
          </div>
          <div className="col-md-6">
            <label>Last Name</label>
            <input type="text" defaultValue="" placeholder="Last Name" />
          </div>
          <div className="col-md-6">
            <div className="medium-icons">
              <label>E-Mail</label>
              <input type="text" defaultValue="" placeholder="Email" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="medium-icons">
              <label>Phone</label>
              <input type="text" defaultValue="" placeholder="Phone" />
            </div>
          </div>
          <div className="col-md-12">
  <label>Your Message</label>
  <textarea
    name="summary"
    cols={40}
    rows={3}
    id="description"
    placeholder="Enter your message here"
    spellCheck="true"
    defaultValue={""}
  />
</div>
<div className='col-md-12'>
<button class="button" style={{width:'100%'}}>Submit</button>
</div>
        </div>
      </div>
    
    </div>
    <div className="col-lg-4 col-md-4 utf_listing_payment_section">
        <br/>
    <img src='images/myimg/contact.jpg' alt='contact'></img>
    
    </div>
  </div>
</div>
</section>


</>
  )
}

export default Contact
