import React from 'react'
import Slider from "react-slick";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-arrow-next" onClick={onClick}>
      <i className="fa fa-chevron-right" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-arrow-prev" onClick={onClick}>
      <i className="fa fa-chevron-left" />
    </div>
  );
};



const Hotel = () => {
  const settings = {
    dots: false,  // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,  // Use custom NextArrow
    prevArrow: <PrevArrow />,  // Use custom PrevArrow
  };


  return (
    <>
      <div
        id="titlebar"
        className="gradient"
        style={{
          backgroundImage: "url(images/myimg/hotels/sandalsuits/lobby.jpg)"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Hotels</h2>
              <nav id="breadcrumbs">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Hotels</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="listing_filter_block">
              <div className="col-md-2 col-xs-3">
                <div className="utf_layout_nav">
                  <a href="#" className="list active">
                    <i className="fa fa-align-justify" />
                  </a>
                </div>
              </div>
              <div className="col-md-10 col-xs-10">
                <div className="sort-by utf_panel_dropdown sort_by_margin float-right">

                  <a href="#">Destination</a>
                  <div className="utf_panel_dropdown-content">
                    <input
                      className="distance-radius"
                      type="range"
                      min={1}
                      max={999}
                      step={1}
                      defaultValue={1}
                      data-title="Select Range"
                    />
                    <div className="panel-buttons">
                      <button className="panel-apply">Apply</button>
                    </div>
                  </div>
                </div>
                <div className="sort-by">
                  <div className="utf_sort_by_select_item sort_by_margin">
                    <select
                      data-placeholder="Sort by Listing"
                      className="utf_chosen_select_single"
                    >
                      <option>Sort by Listing</option>
                      <option>Latest Listings</option>
                      <option>Popular Listings</option>
                      <option>Price (Low to High)</option>
                      <option>Price (High to Low)</option>
                      <option>Highest Reviewe</option>
                      <option>Lowest Reviewe</option>
                      <option>Newest Listing</option>
                      <option>Oldest Listing</option>
                      <option>Random Listings</option>
                    </select>
                  </div>
                </div>
                <div className="sort-by">
                  <div className="utf_sort_by_select_item sort_by_margin">
                    <select
                      data-placeholder="Categories:"
                      className="utf_chosen_select_single"
                    >
                      <option>Categories</option>
                      <option>Restaurant</option>
                      <option>Health</option>
                      <option>Hotels</option>
                      <option>Real Estate</option>
                      <option>Fitness</option>
                      <option>Shopping</option>
                      <option>Travel</option>
                      <option>Shops</option>
                      <option>Nightlife</option>
                      <option>Events</option>
                    </select>
                  </div>
                </div>
                <div className="sort-by">
                  <div className="utf_sort_by_select_item utf_search_map_section">
                    <ul>
                      <li>
                        <a className="utf_common_button" href="#">
                          <i className="fa fa-dot-circle-o" />
                          Near Me
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <a href="/hoteldetail"></a>
                <div className="card">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <Slider {...settings}>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/lobby.jpg"
                            className="d-block w-100"
                            alt="Image 1"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/facade.jpg"
                            className="d-block w-100"
                            alt="Image 2"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
                            className="d-block w-100"
                            alt="Image 3"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bay-room-bathroom-2.jpg"
                            className="d-block w-100"
                            alt="Image 4"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bitters-the-bar.jpg"
                            className="d-block w-100"
                            alt="Image 5"
                          />
                        </div>
                      </Slider>
                      <span className="like-icon" />
                      <div className="utf_listing_prige_block utf_half_list">
                        <span className="utf_meta_listing_price">
                          <i className="fa fa-tag" /> ₹8000 – ₹10,000
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="utf_listing_item_content">
                        <div className="utf_listing_item-inner">
                          <h3>Sandal Suites</h3>
                          <span>
                            <i className="fa fa-map-marker" />
                            Assotech Business Cresterra, 22, Sector-135
                          </span>
                          <span>
                            <i className="fa fa-phone" />
                            0120 623 3333
                          </span>
                          <div className="utf_star_rating_section" data-rating="3.5">
                            <div className="utf_counter_star_rating">(4.5)</div>
                            <span className="star" />
                            <span className="star" />
                            <span className="star" />
                            <span className="star half" />
                            <span className="star empty" />
                          </div>

                          <p className='hotelcardp'>
                            Sandal Suites, operated by Lemon Tree Hotels, is situated ..
                          </p>

                          <a href="#" className="getfind">Book Now</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <a href="/hoteldetail"></a>
                <div className="card">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <Slider {...settings}>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/lobby.jpg"
                            className="d-block w-100"
                            alt="Image 1"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/facade.jpg"
                            className="d-block w-100"
                            alt="Image 2"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bay-room-king-bed-1.jpg"
                            className="d-block w-100"
                            alt="Image 3"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bay-room-bathroom-2.jpg"
                            className="d-block w-100"
                            alt="Image 4"
                          />
                        </div>
                        <div className="myimg">
                          <img
                            src="images/myimg/hotels/sandalsuits/bitters-the-bar.jpg"
                            className="d-block w-100"
                            alt="Image 5"
                          />
                        </div>
                      </Slider>
                      <span className="like-icon" />
                      <div className="utf_listing_prige_block utf_half_list">
                        <span className="utf_meta_listing_price">
                          <i className="fa fa-tag" /> ₹8000 – ₹10,000
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="utf_listing_item_content">
                        <div className="utf_listing_item-inner">
                          <h3>Sandal Suites</h3>
                          <span>
                            <i className="fa fa-map-marker" />
                            Assotech Business Cresterra, 22, Sector-135
                          </span>
                          <span>
                            <i className="fa fa-phone" />
                            0120 623 3333
                          </span>
                          <div className="utf_star_rating_section" data-rating="3.5">
                            <div className="utf_counter_star_rating">(4.5)</div>
                            <span className="star" />
                            <span className="star" />
                            <span className="star" />
                            <span className="star half" />
                            <span className="star empty" />
                          </div>

                          <p className='hotelcardp'>
                            Sandal Suites, operated by Lemon Tree Hotels, is situated ..
                          </p>

                          <a href="#" className="getfind">Book Now</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* add more card here */}
            </div>
            <div className="clearfix" />

          </div>
          {/* Sidebar */}
          <div className="col-lg-4 col-md-4">
            <div className="sidebar">
              <div className="utf_box_widget margin-bottom-35">
                <h3>list of Top Hotel In Noida</h3>
                <div className="row with-forms">
                  <div className="col-md-12">
                    <input type="text" placeholder="Enter Name" defaultValue="" />
                  </div>
                </div>
                <div className="row with-forms">
                  <div className="col-md-12">
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      defaultValue=""
                    />
                  </div>
                </div>
                <button className="button fullwidth_block margin-top-5">
                  Send Enquiry
                </button>
              </div>
              <div className="utf_box_widget margin-top-35 margin-bottom-75">
                <h3>
                  <i className="sl sl-icon-folder-alt" /> Categories
                </h3>
                <ul className="utf_listing_detail_sidebar">
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Travel</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Nightlife</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Fitness</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Real Estate</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Restaurant</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> <a href="#">Dance Floor</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>

  )
}

export default Hotel
