import React, { useState } from 'react';

const AddBusinessForm = () => {
  const [formData, setFormData] = useState({
    listingTitle: '',
    keywords: '',
    category: '',
    tags: '',
    listingTags: '',
    city: '',
    address: '',
    latitude: '',
    longitude: '',
    name: '',
    email: '',
    title: '',
    tagline: '',
    description: '',
    amenities: [],
    openingHours: {
      monday: { open: '', close: '' },
      tuesday: { open: '', close: '' },
      wednesday: { open: '', close: '' },
      thursday: { open: '', close: '' },
      friday: { open: '', close: '' },
      saturday: { open: '', close: '' },
      sunday: { open: '', close: '' },
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      amenities: checked
        ? [...prevState.amenities, name]
        : prevState.amenities.filter((amenity) => amenity !== name),
    }));
  };

  const handleOpeningHoursChange = (day, timeType, value) => {
    setFormData((prevState) => ({
      ...prevState,
      openingHours: {
        ...prevState.openingHours,
        [day]: { ...prevState.openingHours[day], [timeType]: value },
      },
    }));
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div id="utf_add_listing_part">
          <div className="add_utf_listing_section">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-user"></i> Have an Account?</h3>
            </div>
            <div className="row with-forms">
              <div className="col-md-12">
                <div className="form-group lis-relative">
                  Sign in If you don’t have an account you can create one below by entering your email address/username. Your account details will be confirmed via email. <a href="#dialog_signin_part" className="login_form sign-in popup-with-zoom-anim">Sign in</a>
                </div>
              </div>
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-tag"></i> Categories & Tags</h3>
            </div>
            <div className="row with-forms">
              <div className="col-md-6">
                <h5>Listing Title</h5>
                <input
                  type="text"
                  className="search-field"
                  name="listingTitle"
                  placeholder="Listing Title"
                  value={formData.listingTitle}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <h5>Keywords</h5>
                <input
                  type="text"
                  name="keywords"
                  placeholder="Keywords..."
                  value={formData.keywords}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row with-forms">
              <div className="col-md-6">
                <h5>Category</h5>
                <div className="intro-search-field utf-chosen-cat-single">
                  <select
                    className="selectpicker default"
                    data-selected-text-format="count"
                    data-size="7"
                    title="Select Category"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                  >
                    <option value="Eat & Drink">Eat & Drink</option>
                    <option value="Shops">Shops</option>
                    <option value="Hotels">Hotels</option>
                    <option value="Restaurants">Restaurants</option>
                    <option value="Fitness">Fitness</option>
                    <option value="Events">Events</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <h5>Tags (optional)</h5>
                <div className="intro-search-field utf-chosen-cat-single">
                  <select
                    className="selectpicker default"
                    data-selected-text-format="count"
                    data-size="7"
                    title="Select Tags"
                    name="tags"
                    value={formData.tags}
                    onChange={handleInputChange}
                  >
                    <option value="One">One</option>
                    <option value="Two">Two</option>
                    <option value="Three">Three</option>
                    <option value="Four">Four</option>
                    <option value="Five">Five</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row with-forms">
              <div className="col-md-12">
                <h5>Listing Tags</h5>
                <input
                  type="text"
                  className="search-field"
                  name="listingTags"
                  placeholder="Listing Tags"
                  value={formData.listingTags}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-map"></i> Location</h3>
            </div>
            <div className="utf_submit_section">
              <div className="row with-forms">
                <div className="col-md-6">
                  <h5>City</h5>
                  <div className="intro-search-field utf-chosen-cat-single">
                    <select
                      className="selectpicker default"
                      data-selected-text-format="count"
                      data-size="7"
                      title="Select City"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    >
                      <option value="New York">New York</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Chicago">Chicago</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Spain">Spain</option>
                      <option value="Iraq">Iraq</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>Address</h5>
                  <input
                    type="text"
                    className="input-text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12">
                  <h5>Decimal Degrees</h5>
                  <div className="row with-forms">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="input-text"
                        name="latitude"
                        placeholder="40.7324319"
                        value={formData.latitude}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="input-text"
                        name="longitude"
                        placeholder="-73.824807777775"
                        value={formData.longitude}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div id="utf_listing_location" className="col-md-12 utf_listing_section">
                  <div id="utf_single_listing_map_block">
                    <div
                      id="utf_single_listingmap"
                      data-latitude={formData.latitude}
                      data-longitude={formData.longitude}
                      data-map-icon="im im-icon-Hamburger"
                    />
                    <a href="#" id="utf_street_view_btn">Street View</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-picture"></i> Images</h3>
            </div>
            <div className="row with-forms">
              <div className="utf_submit_section col-md-4">
                <h4>Logo</h4>
                <form action="https://ulisting.utouchdesign.com/ulisting_ltr/file-upload" className="dropzone"></form>
              </div>
              <div className="utf_submit_section col-md-4">
                <h4>Cover Image</h4>
                <form action="https://ulisting.utouchdesign.com/ulisting_ltr/file-upload" className="dropzone"></form>
              </div>
              <div className="utf_submit_section col-md-4">
                <h4>Gallery Images</h4>
                <form action="https://ulisting.utouchdesign.com/ulisting_ltr/file-upload" className="dropzone"></form>
              </div>
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-docs"></i> Details</h3>
            </div>
            <div className="row with-forms">
              <div className="col-md-12">
                <h5>Description</h5>
                <textarea
                  className="WYSIWYG"
                  name="description"
                  cols="40"
                  rows="3"
                  id="summary"
                  spellCheck="true"
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="col-md-4">
                <h5>Phone (optional)</h5>
                <input
                  type="text"
                  className="input-text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <h5>Email</h5>
                <input
                  type="text"
                  className="input-text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <h5>Website (optional)</h5>
                <input
                  type="text"
                  className="input-text"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-location"></i> Amenities</h3>
            </div>
            <div className="checkboxes in-row margin-bottom-20">
              {['Free Wi-Fi', 'Parking', 'TV', 'Swimming Pool', 'Spa', 'Wheelchair Access', 'Pets Allowed', 'Air Conditioning'].map((amenity, index) => (
                <div key={index}>
                  <input
                    id={`check-${index}`}
                    type="checkbox"
                    name={amenity}
                    checked={formData.amenities.includes(amenity)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`check-${index}`}>{amenity}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-clock"></i> Opening Hours</h3>
            </div>
            <div className="row with-forms">
              {Object.keys(formData.openingHours).map((day) => (
                <div className="col-md-2" key={day}>
                  <h5>{day.charAt(0).toUpperCase() + day.slice(1)}</h5>
                  <input
                    type="time"
                    className="input-text"
                    value={formData.openingHours[day].open}
                    onChange={(e) => handleOpeningHoursChange(day, 'open', e.target.value)}
                  />
                  <input
                    type="time"
                    className="input-text"
                    value={formData.openingHours[day].close}
                    onChange={(e) => handleOpeningHoursChange(day, 'close', e.target.value)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="add_utf_listing_section margin-top-45">
            <div className="utf_add_listing_part_headline_part">
              <h3><i className="sl sl-icon-credit-card"></i> Pricing</h3>
            </div>
            <div className="row with-forms">
              <div className="col-md-4">
                <h5>Pricing</h5>
                <div className="select-input disabled-first-option">
                  <input
                    type="text"
                    placeholder="Price per night"
                    name="pricing"
                    value={formData.pricing}
                    onChange={handleInputChange}
                  />
                  <select>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <h5>Maximum Guests (optional)</h5>
                <input
                  type="number"
                  className="input-text"
                  name="maxGuests"
                  value={formData.maxGuests}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <h5>Minimum Stay (optional)</h5>
                <input
                  type="number"
                  className="input-text"
                  name="minStay"
                  value={formData.minStay}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="utf_listing_submit_section">
          <button type="submit" className="button preview">Submit Listing</button>
        </div>
      </div>
    </div>
  );
};

export default AddBusinessForm;
