
import Home from "./components/Home";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Category from "./pages/Category";
import Hotel from "./pages/hotel/Hotel";
import HotelDetail from "./pages/hotel/HotelDetail";
import Contact from "./pages/Contact";
import Addlisting from "./pages/Addlisting";
import About from "./pages/About";
function App() {


  return (
    <>

      <div id="main_wrapper">
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/category" element={<Category />} />
              <Route path="/hotel" element={<Hotel />} />
              <Route path="/hoteldetail" element={<HotelDetail />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/addlisting" element={<Addlisting />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </Layout>
        </Router>
      </div>
    </>
  );
}

export default App;
