import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

      
  return (
 <>
<header id="header_part" className="fullwidth">
  <div id="header">
    <div className="container">
      <div className="utf_left_side">
        <div id="logo">
          
          <Link to="/">
            <img src="images/logo.png" alt="" />
          </Link>
        </div>
        <div className="mmenu-trigger">
          <button className="hamburger utfbutton_collapse" type="button">
            <span className="utf_inner_button_box">
              <span className="utf_inner_section" />
            </span>
          </button>
        </div>
        <nav id="navigation" className="style_one">
          <ul id="responsive">
            <li>
              <Link className="current" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/">Blog</Link>
            </li>
            <li>
              <Link to="/">About Us</Link>
            </li>
            <li>
              <Link to="/category">Categories</Link>
            </li>
            <li>
              <Link to="/">Contact Us</Link>
            </li>
            <li>
              <Link to="/">
                
                <span className="badge blinking">
                  <i className="bi bi-megaphone" />
                </span>
                Advertise
              </Link>
            </li>
          </ul>
        </nav>
        <div className="clearfix" />
      </div>
      <div className="utf_right_side">
        <div className="header_widget">
   
         <Link
            to="/"
            className="button border sign-in "
          >
            <i className="fa fa-sign-in" /> Sign In
          </Link> 
          <Link to="/" className="button border with-icon">
            <i className="sl sl-icon-user" /> Add Listing
          </Link>
        </div>
      </div>
      
      {/* <div id="dialog_signin_part" className="zoom-anim-dialog mfp-hide">
        <div className="small_dialog_header">
          <h3>Sign In</h3>
        </div>
        <div className="utf_signin_form style_one">
          <ul className="utf_tabs_nav">
            <li className="">
              <a href="#tab1">Log In</a>
            </li>
            <li>
              <a href="#tab2">Register</a>
            </li>
          </ul>
          <div className="tab_container alt">
            <div className="tab_content" id="tab1" style={{ display: "none" }}>
              <form method="post" className="login">
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="username">
                    <input
                      type="text"
                      className="input-text"
                      name="username"
                      id="username"
                      defaultValue=""
                      placeholder="Username"
                    />
                  </label>
                </p>
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="password">
                    <input
                      className="input-text"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                    />
                  </label>
                </p>
                <div className="utf_row_form utf_form_wide_block form_forgot_part">
                  
                  <span className="lost_password fl_left">
                    
                    <Link to="javascript:void(0);">Forgot Password?</Link>
                  </span>
                  <div className="checkboxes fl_right">
                    <input id="remember-me" type="checkbox" name="check" />
                    <label htmlFor="remember-me">Remember Me</label>
                  </div>
                </div>
                <div className="utf_row_form">
                  <input
                    type="submit"
                    className="button border margin-top-5"
                    name="login"
                    defaultValue="Login"
                  />
                </div>
                <div className="utf-login_with my-3">
                  <span className="txt">Or Login in With</span>
                </div>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <Link
                      to="javascript:void(0);"
                      className="social_bt facebook_btn mb-0"
                    >
                      <i className="fa fa-facebook" /> Facebook
                    </Link>
                  </div>
                  <div className="col-md-6 col-6">
                    <Link
                      to="javascript:void(0);"
                      className="social_bt google_btn mb-0"
                    >
                      <i className="fa fa-google" /> Google
                    </Link>
                  </div>
                </div>
              </form>
            </div>
            <div className="tab_content" id="tab2" style={{ display: "none" }}>
              <form method="post" className="register">
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="username2">
                    <input
                      type="text"
                      className="input-text"
                      name="username"
                      id="username2"
                      defaultValue=""
                      placeholder="Username"
                    />
                  </label>
                </p>
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="email2">
                    <input
                      type="text"
                      className="input-text"
                      name="email"
                      id="email2"
                      defaultValue=""
                      placeholder="Email"
                    />
                  </label>
                </p>
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="password1">
                    <input
                      className="input-text"
                      type="password"
                      name="password1"
                      id="password1"
                      placeholder="Password"
                    />
                  </label>
                </p>
                <p className="utf_row_form utf_form_wide_block">
                  <label htmlFor="password2">
                    <input
                      className="input-text"
                      type="password"
                      name="password2"
                      id="password2"
                      placeholder="Confirm Password"
                    />
                  </label>
                </p>
                <input
                  type="submit"
                  className="button border fw margin-top-10"
                  name="register"
                  defaultValue="Register"
                />
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
</header>

 </>
  )
}

export default Header
